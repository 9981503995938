.dropdown {
	position: relative;
	display: inline-block;

	&.position-left {
		.dropdown-content {
			left: 0;
		}
	}
	&.position-right {
		.dropdown-content {
			right: 0;
		}
	}
	&.position-top {
		.dropdown-content {
			top: 0;
		}
	}
}

.dropbtn {
	max-width: 100%;
	width: fit-content;
	cursor: pointer;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: transparent;
	min-width: 160px;
	width: fit-content;
	max-height: 60vh;
	margin-top: 4px;
	overflow: auto;
	z-index: 1050;
	width: 100%;
	min-width: fit-content;
}

.show {
	display: block;
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';