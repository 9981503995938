.tabs {
	[class~='ant-tabs-nav'] {
		margin: 0 10px;

		&::before {
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		}

		[class~='ant-tabs-tab'] {
			padding: 12px 20px;
		}
	}
}

.items {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 12px 0 7px;

	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		border-radius: 6px;
		background: rgba(255, 255, 255, 0.2);
	}
	&::-webkit-scrollbar-thumb {
		width: 6px;
		border-radius: 4px;
		background: #139efb;
	}

	.item {
		display: flex;
		gap: 12px;
		padding: 5px 10px;

		&:hover {
			background: rgba(255, 255, 255, 0.1);
		}

		.image {
			min-height: 40px;
			min-width: 40px;
			height: 40px;
			width: 40px;
			object-fit: contain;
			border-radius: 4px;
		}
		.info {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.title {
				color: #fff;
				font-size: 14px;
				font-weight: 700;
			}
			.description {
				color: rgba(255, 255, 255, 0.4);
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
}

.empty {
	padding: 12px 10px;
	color: #ea4335;
	font-size: 12px;
	font-weight: 500;
}

.drawer {
	[class~='ant-drawer-mask'] {
		display: none;
	}

	[class~='ant-drawer-content-wrapper'] {
		height: 100% !important;
		background: #383838;
		color: #ffffff;
		box-shadow: unset;

		[class~='ant-drawer-content'] {
			height: 100%;
			background: unset;

			[class~='ant-drawer-header'] {
				display: none;
			}

			[class~='ant-drawer-body'] {
				height: 100%;
				position: relative;
				// z-index: 0;
				padding: 10px 0;

				display: flex;
				flex-direction: column;
				// overflow: auto;
			}
		}
	}

	.input-container {
		width: 100%;
		margin: 0 10px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);

		&:hover,
		&:focus {
			border-color: transparent;
		}
	}

	.tabs {
		[class~='ant-tabs-nav'] {
			// margin: 0 10px;
			margin: 0;

			[class~='ant-tabs-tab'] {
				padding: 12px;
			}
		}

		[class~='ant-tabs-nav-operations'] {
			display: none;
		}
	}

	.items {
		.item {
			padding: 5px 10px;
		}
	}
}

.dropdown {
	min-width: 400px !important;
	max-width: 100vw !important;
	border-radius: 4px;
	background: #383838;
	border: none;
	box-shadow: none;

	.items {
		max-height: 285px;
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';