.search-bar {
	position: relative;
	width: 100%;

	.icon {
		position: absolute;
		top: 8px;
		left: 8px;
		z-index: 2;
	}

	.input {
		height: 36px;
		padding: 8px;
		padding-left: 35px;
		border-radius: 4px;
		background: #383838;
		color: #ffffff;
		font-size: 14px;
		font-weight: 400;

		input {
			background: transparent;
		}
	}

	@media only screen and (max-width: @screen-mobile-max) {
		.icon {
			position: absolute;
			left: 6px;
		}
		.input {
			height: 32px;
			padding: 6px;
			padding-left: 28px;
			font-size: 11px;
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';